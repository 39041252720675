<template>
    <div v-if="getActiveDevice && getActiveLocation">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->

                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <!-- Avatar -->
                            <div class="avatar avatar-lg header-avatar-top">
                                <img
                                    :src="
                                        getActiveDevice.Device.MediumImageUrl ||
                                        '/images/zesec-placeholder.png'
                                    "
                                    alt="..."
                                    class="
                                        avatar-img
                                        border border-4 border-body
                                    "
                                />
                            </div>
                        </div>
                        <div class="col mb-3 ml-n3 ml-md-n2">
                            <!-- Pretitle -->
                            <h6 class="header-pretitle">
                                {{ $t("doors.door") }}
                            </h6>

                            <!-- Title -->
                            <h1 class="header-title">
                                {{ getActiveDevice.Device.Name }}
                            </h1>
                        </div>
                        <div
                            class="col col-md-auto mt-2 mt-md-0 mb-md-3"
                            v-if="
                                $can('manageDoors', getActiveLocation) &&
                                getActiveDevice.Device.ProviderType != 2 && !roleFlag
                            "
                        >
                            <b-dropdown
                                :text="$t('buttons.manage')"
                                right
                                variant="primary"
                                toggle-tag="a"
                                class="float-right"
                            >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="editDevice"
                                    >{{ $t("buttons.edit") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deleteDevice"
                                    >{{ $t("buttons.delete") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deviceImage()"
                                    >{{ $t("doors.image") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deviceQR()"
                                    v-if="isButtonManageDoorShown">{{ $t("doors.generate_qr") }}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <div
                            class="col col-md-auto mt-2 mt-md-0 mb-md-3"
                            v-if="
                                getActiveDevice.Device.ProviderType !== 2 && roleFlag && getActiveUserRole !== 'KeyManager'
                            "
                        >
                            <b-dropdown
                                :text="$t('buttons.manage')"
                                right
                                variant="primary"
                                toggle-tag="a"
                                class="float-right"
                            >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="editDevice"
                                    >{{ $t("buttons.edit") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deleteDevice"
                                    >{{ $t("buttons.delete") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deviceImage()"
                                    >{{ $t("doors.image") }}</b-dropdown-item
                                >
                                <b-dropdown-item
                                    class="text-left text-truncate"
                                    @click="deviceQR()"
                                    v-if="isButtonManageDoorShown">{{ $t("doors.generate_qr") }}</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>

                    <!-- / .row -->
                    <div class="row align-items-center">
                        <div class="col">
                            <!-- Nav -->
                            <ul class="nav nav-tabs nav-overflow header-tabs">
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 0"
                                        v-bind:class="{ active: tabIndex == 0 }"
                                        >{{ $t("doors.details") }}</a
                                    >
                                </li>
                                <li
                                    class="nav-item"
                                    v-if="roleFlag"
                                >
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 1"
                                        v-bind:class="{ active: tabIndex == 1 }"
                                        >{{ $t("users.users") }}</a
                                    >
                                </li>
                                <li
                                    class="nav-item"
                                    v-if="roleFlag"
                                >
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 2"
                                        v-bind:class="{ active: tabIndex == 2 }"
                                        >{{ $t("keys.keys") }}</a
                                    >
                                </li>
                              <!--   && (userRole == 'CompanyOwner' || userRole == 'CompanyManager' || userRole == 'LocationManager') -->
                                <li
                                    class="nav-item"
                                    v-if="false"
                                >
                                    <a
                                        href="#"
                                        class="nav-link"
                                        @click.stop.prevent="tabIndex = 3"
                                        v-bind:class="{ active: tabIndex == 3 }"
                                        >{{ $t("activity.activity") }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- / .header-body -->
            </div>
        </div>

        <device-tabs
            v-if="showTabs && getActiveDevice"
            :device="getActiveDevice"
            :tabIndex="tabIndex"
        />
        <zs-device-delete-modal
            :locationId="getActiveDevice.Device.LocationId"
        />
        <zs-image-upload-modal @imageUploaded="updateDoorImage" />
        <zs-device-qr-modal />
    </div>
</template>

<script>
import DeviceQRModal from "@/components/modals/DeviceQR";
import DeleteDeviceModal from "@/components/modals/DeleteDevice";
import ImageUploadModal from "@/components/modals/ImageUpload";
import Tabs from "@/components/device/Tabs";

import { mapGetters } from "vuex";

import { LOCATION_GET } from "@/store/types/locations";
import {
    DEVICE_PATCH,
    DEVICE_GET,
    DEVICE_CODE_GET,
    MX_DEVICE_INFO,
    MX_DEVICE_INFO_CLEAR,
    SALTO_DEVICE_INFO_CLEAR, SALTO_DEVICE_INFO
} from "@/store/types/devices";
import { EventBus } from "@/main.js"

import {
    M_DEVICE_DELETE,
    M_DEVICE_FORM,
    M_IMAGE_UPLOAD,
    M_DEVICE_QR,
} from "@/components/modals/types";

import { IMAGE_TYPE_DOOR } from "@/handlers/const";

import modalMixin from "@/mixins/modalMixin";
import { sleep } from "@/store/modules/global";

export default {
    mixins: [modalMixin],
    data() {
        return {
            filter: "",
            tabIndex: 0,
            showTabs: false,
            roleFlag: false,
        };
    },
    computed: {
        ...mapGetters(["getActiveDevice", "getActiveLocation", "getActiveUserRole", "getMxDeviceInfo", "getInitProcessing"]),
        isButtonManageDoorShown() {
            return this.$can('manageDoors', this.getActiveLocation)
                && this.getActiveDevice.Device.ProviderType === 3
                || this.getActiveDevice.Device.ProviderType === 5
                || this.getActiveDevice.Device.ProviderType === 6
                || this.getActiveDevice.Device.ProviderType === 7;
        },
    },

    async created() {
        await this.$store.dispatch(LOCATION_GET, this.$route.params.locationId);
        await this.$store
            .dispatch(DEVICE_GET, this.$route.params.doorId)
            .then(() => {
                let respDoorCode;
                this.$store.dispatch(DEVICE_CODE_GET, this.$route.params.doorId).then((response) => {
                    respDoorCode = response.doorcode;
                });
                this.showTabs = true;
            });

        // load MX device info
        this.$store.commit(MX_DEVICE_INFO_CLEAR)
        if (this.getActiveDevice?.Device?.ProviderType === 3) {
            await this.$store.dispatch(MX_DEVICE_INFO, this.$route.params.doorId);
        }

        // load Salto Online/Offline device info
        this.$store.commit(SALTO_DEVICE_INFO_CLEAR)
        if (this.getActiveDevice?.Device?.ProviderType === 4 || this.getActiveDevice?.Device?.ProviderType === 5) {
            await this.$store.dispatch(SALTO_DEVICE_INFO, this.$route.params.doorId);
        }

        // wait for init completed
        for (let i = 0; i < 30; i++) {
            await sleep(100)
            if (this.getInitProcessing === false) {
                break
            }
        }

        // console.log("getActiveUserRole:"+this.getActiveUserRole)
        if (this.getActiveUserRole == "CompanyOwner" || this.getActiveUserRole == "CompanyManager" || this.getActiveUserRole == "LocationManager" || this.getActiveUserRole == "KeyManager") {
            this.roleFlag = true
        }
        //console.log("this.roleFlag:"+this.roleFlag)
    },
    methods: {
        editDevice() {
            this.showModal(
                { device: this.getActiveDevice.Device },
                M_DEVICE_FORM
            );
        },
        deleteDevice() {
            this.showModal(
                { device: this.getActiveDevice.Device },
                M_DEVICE_DELETE
            );
        },
        deviceQR() {
            this.showModal(
                { device: this.getActiveDevice.Device },
                M_DEVICE_QR
            );
        },
        deviceImage() {
            this.showModal(
                {
                    image: {
                        url:
                            this.getActiveDevice.Device.MediumImageUrl ||
                            "/images/zesec-placeholder.png",
                        type: IMAGE_TYPE_DOOR,
                    },
                },
                M_IMAGE_UPLOAD
            );
        },
        updateDoorImage(response) {
            this.$store
                .dispatch(DEVICE_PATCH, {
                    ...this.getActiveDevice.Device,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(
                        DEVICE_GET,
                        this.getActiveDevice.Device.Id
                    );
                })
                .catch((error) => {});
        },
    },

    components: {
        "device-tabs": Tabs,
        "zs-device-delete-modal": DeleteDeviceModal,
        "zs-image-upload-modal": ImageUploadModal,
        "zs-device-qr-modal": DeviceQRModal,
    },
};
</script>
