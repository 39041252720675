<template>
    <b-modal
        :id="type"
        :title="$t('modals.title_revoke_door')"
        :ok-title="$t('buttons.delete')"
        :cancel-title="$t('buttons.cancel')"
        ok-variant="outline-danger"
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @ok="deleteUser"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid v-if="type == getModalType">
            <b-row>
                <b-col cols="12">
                    {{
                        $t("modals.text_revoke_door", {
                            doorName: getModalData.user.Door.Device.Name,
                            userName: getModalData.user.Receiver.FirstName + " " + getModalData.user.Receiver.LastName,
                        })
                    }}
                    <span class="bold-font-style">{{
                        $t("modals.are_you_sure")
                    }}</span>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { KEY_USER_REMOVE } from "@/store/types/keys";
import { M_DELETE_DOOR_USER } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";

import { DEVICE_USERS_GET} from "@/store/types/devices";

export default {
    data() {
        return {
            type: M_DELETE_DOOR_USER,
            user: {},
        };
    },
    computed: {
        ...mapGetters(["getModalData", "getModalType"]),
    },

    methods: {
        setData() {
            this.user = {...this.getModalData.user};
            if (this.user.Receiver.FirstName == null || this.user.Receiver.FirstName == "null" || this.user.Receiver.FirstName == "") {
                this.user.Receiver.FirstName = '';
            }
            if (this.user.Receiver.LastName == null || this.user.Receiver.LastName == "null" || this.user.Receiver.LastName == "") {
                this.user.Receiver.LastName = '';
            }
        },
        deleteUser() {
            this.$store
                .dispatch(KEY_USER_REMOVE, {
                    keyId: this.user.Key.Id,
                    userId: this.user.Receiver.Id,
                    locationId: this.user.Key.LocationId,
                })
                .then((response) => {
                    this.$store.dispatch(
                        DEVICE_USERS_GET,                        
                        this.$route.params.doorId
                    );
                });
        },
        onClose() {
            this.$store.commit(CLEAR_MODAL_DATA);
            this.user = null;
        },
    },
};
</script>
