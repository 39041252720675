<template>
    <b-modal
        :id="type"
        :title="$t('doors.door')"
        size="lg"
        hide-footer
        modal-class="fixed-right"
        dialog-class="modal-dialog-vertical"
        @show="setData"
        @hide="onClose"
    >
        <b-container fluid>
            <b-row>
                <b-col cols="12" style="padding-left: 54px !important;">
                    <b-form>
                        <b-form-group
                            :label="$t('doors.name')"
                        >
                            <b-form-input
                                v-model="$v.device.Name.$model"
                                :state="validateState('Name')"
                                id="deviceName"
                                :placeholder="$t('doors.name')"
                            />
                            <b-form-invalid-feedback id="deviceName-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_range", {
                                        range: "3-30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            :label="$t('doors.type')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.DoorType.$model"
                                    :state="validateState('DoorType')"
                                    id="deviceType"
                                    :options="deviceTypesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="deviceType-feedback"
                                >
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("doors.type_select") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            :label="$t('doors.provider')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.ProviderType.$model"
                                    :state="validateState('ProviderType')"
                                    id="deviceProvider"
                                    :options="deviceProvidersOptions"
                                    :disabled="!createMode"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="deviceProvider-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.provider_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>

                        <b-form-group  v-if="device.ProviderType === 1"
                            :label="$t('doors.code_type')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.DoorCodeType.$model"
                                    :state="validateState('DoorCodeType')"
                                    id="DoorCodeType"
                                    :options="doorpadCodeTypesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="DoorCodeType-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.code_type_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>
                        <b-form-group  v-if="isSaltoInstallationsShown"
                            :label="$t('doors.saltoInstallation')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.SaltoInstallationId.$model"
                                    :state="validateState('SaltoInstallationId')"
                                    id="saltoInstallation"
                                    @change="setRelaysList"
                                    :options="saltoInstallationOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="saltoInstallation-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.saltoInstallation_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>
                        <b-form-group  v-if="device.ProviderType === 5"
                            :label="$t('doors.availableDevices')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.SaltoOnlineDeviceId.$model"
                                    :state="validateState('SaltoOnlineDeviceId')"
                                    id="availableDevices"
                                    :options="availableDevicesOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="availableDevices-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("doors.availableDevices_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>
                        <b-form-group  v-if="isSaltoRelaysShown"
                            :label="$t('relays.availableRelays')"
                        >
                            <b-input-group>
                                <b-form-select
                                    v-model="$v.device.SaltoOnlineRelayId.$model"
                                    :state="validateState('SaltoOnlineRelayId')"
                                    id="availableRelays"
                                    :options="availableRelaysOptions"
                                ></b-form-select>
                                <b-form-invalid-feedback
                                    id="availableRelays-feedback"
                                    >
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("relays.availableRelays_select")
                                    }}</b-form-invalid-feedback
                                >
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="isDoorCodeShown"
                            :label="$t('doors.code')"
                        >
                            <b-form-input
                                v-model="$v.device.Code.$model"
                                :state="validateState('Code')"
                                id="Code"
                                :placeholder="$t('doors.placeholder')"
                            />
                            <b-form-invalid-feedback id="Code-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_length_number_range", {
                                        range: "4",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType === 2"
                            :label="$t('doors.yale_sid')"
                        >
                            <b-input-group>
                                <b-form-input
                                    v-model="$v.device.YaleSid.$model"
                                    :state="validateState('YaleSid')"
                                    id="deviceYaleSid"
                                    :placeholder="$t('doors.yale_sid')"
                                />
                                <b-form-invalid-feedback id="deviceYaleSid-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{ $t("errors.validation_required") }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType == 3"
                            :label="$t('doors.honest_box_lock_id')"
                        >
                            <b-input-group>
                                <b-form-input
                                    v-model="$v.device.HonestBoxLockId.$model"
                                    :state="validateState('HonestBoxLockId')"
                                    id="deviceHonestBoxLockId"
                                    :placeholder="
                                        $t('doors.honest_box_lock_id')
                                    "
                                />
                                <b-form-invalid-feedback id="deviceHonestBoxLockId-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_number_range", {
                                            range: "1-10",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType === 6"
                            :label="$t('doors.inlet_online_lock_id')"
                        >
                            <b-input-group>
                                <b-form-input
                                    v-model.number="$v.device.InletOnlineLockId.$model"
                                    :state="validateState('InletOnlineLockId')"
                                    id="deviceInletOnlineLockId"
                                    :placeholder="
                                        $t('doors.inlet_online_lock_id')
                                    "
                                />
                                <b-form-invalid-feedback id="deviceInletOnlineLockId-feedback">
                                    <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                    {{
                                        $t("errors.validation_length_number_range", {
                                            range: "1-10",
                                        })
                                    }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group
                            v-if="device.ProviderType === 4"
                        >
                        </b-form-group>

                        <b-form-group
                            v-if="device.ProviderType === 1"
                            :label="$t('doors.code_instruction')"
                        >
                            <b-form-input
                                v-model="$v.device.DoorCodeDescription.$model"
                                :state="validateState('DoorCodeDescription')"
                                id="DoorCodeDescription"
                                :placeholder="$t('doors.code_instruction')"
                            />
                            <b-form-invalid-feedback id="DoorCodeDescription-feedback">
                                <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                                {{
                                    $t("errors.validation_max_length", {
                                        length: "30",
                                    })
                                }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                            v-if="
                                !ownerDanalockConnected &&
                                device.ProviderType === 0
                            "
                            ><b-alert show variant="warning">{{
                                $t("providers.danalock_owner_warning")
                            }}</b-alert></b-form-group
                        >
                        <b-form-group
                          v-if="device.ProviderType === 0 && false"
                          :label="$t('doors.danalock_mac_address')"
                        >
                          <b-form-input
                            v-model="$v.device.SerialNumber.$model"
                            :disabled="!createMode"
                            :state="validateState('SerialNumber')"
                            id="SerialNumber"
                            :placeholder="$t('doors.danalock_mac_address')"
                          />
                          <b-form-invalid-feedback id="DanalockMAC-feedback">
                            <b-icon icon="exclamation-circle" variant="danger"></b-icon>
                            {{
                              $t("errors.validation_mac")
                            }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <b-button
                            class="input-field"
                            @click.prevent.stop="postDevice"
                            v-if="createMode"
                            >{{ $t("doors.create") }}</b-button
                        >
                        <b-button
                            class="input-field"
                            @click.prevent.stop="editDevice"
                            v-else
                            >{{ $t("doors.update") }}</b-button
                        >
                    </b-form>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { deviceProviders, doorTypes, doorpadCodeTypes} from "@/handlers/enums";
import { LOCATION_GET } from "@/store/types/locations";
import { DEVICE_CREATE, DEVICE_PATCH, DEVICE_CODE_PATCH, DEVICE_GET, DEVICE_CODE_GET} from "@/store/types/devices";
import {
    SALTO_INSTALLATION_GET,
    SALTO_ONLINE_DEVICE_GET,
    SALTO_RELAYS_GET,
    SALTO_INSTALLATION_BY_ID_GET,
    SALTO_ONLINE_DEVICE_BY_ID_GET,
    SALTO_ONLINE_DEVICE_CREATE,
    SALTO_ERROR } from "@/store/types/salto";
import { M_DEVICE_FORM } from "@/components/modals/types";
import { CLEAR_MODAL_DATA } from "@/store/types/global";
import { CHECK_DANALOCK } from "@/store/types/providers";
import { validationMixin } from "vuelidate";
import {
    required,
    minLength,
    maxLength,
    requiredIf,
    numeric,
    macAddress,
} from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    data() {
        return {
            type: M_DEVICE_FORM,
            createMode: true,
            device: {},
            submitted: false,
            ownerDanalockConnected: false,
            salto: [],
            filter: "",
            filterDev: "",
        };
    },
    validations: {
        device: {
            Name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30),
            },
            DoorType: {
                required,
            },
            ProviderType: {
                required,
            },
            SaltoInstallationId: {
                required: requiredIf(function () {
                    return this.device.ProviderType === 5 || this.device.ProviderType === 7;
                }),
            },
            SaltoOnlineDeviceId: {
                required: requiredIf(function () {
                    return this.device.ProviderType === 5;
                }),
            },
            SaltoOnlineRelayId: {
                required: requiredIf(function () {
                    return this.device.ProviderType === 7;
                }),
            },
            DoorCodeType: {
                required: requiredIf(function () {
                    return ( this.device.ProviderType === 1);
                }),
            },
            YaleSid: {
                required: requiredIf(function () {
                    return this.device.ProviderType === 2;
                }),
            },
            HonestBoxLockId: {
                required: requiredIf(function () {
                    return this.device.ProviderType === 3;
                }),
                numeric,
                minLength: minLength(1),
                maxLength: maxLength(10),
            },
            InletOnlineLockId: {
                required: requiredIf(function () {
                    return this.device.ProviderType === 6;
                }),
                numeric,
                minLength: minLength(1),
                maxLength: maxLength(10),
            },
            Code: {
                required: requiredIf(function () {
                    return this.isDoorCodeShown;
                }),
                numeric,
                minLength: minLength(4),
                maxLength: maxLength(4),
            },
            DoorCodeDescription: {
                maxLength: maxLength(30),
            },
            SerialNumber: {
               maxLength: maxLength(17),
               macAddress: macAddress(),
            },
        },
    },
    props: ["locationId", "companyId"],
    computed: {
        ...mapGetters(["getSaltoDeviceInfo", "getModalData", "getModalType", "getActiveCompany", "getSaltoInstallation", "getSaltoOnlineDevices", "getAllLocations", "getSaltoRelaysList"]),
        deviceTemplate() {
            return {
                Name: "",
                DoorType: null,
                ProviderType: null,
                LocationId: this.locationId,
                DoorCodeType: null,
                Code: null,
                DoorCodeDescription: null,
                SerialNumber: null,
                YaleSid: null,
                HonestBoxLockId: null,
                InletOnlineLockId: null,
                SaltoInstallationId: null,
                SaltoOnlineDeviceId: null,
                SaltoOnlineRelayId: null,
            };
        },
        deviceProvidersOptions() {
            return [
                {
                    value: null,
                    text: this.$t("doors.provider_select"),
                    disabled: true,
                },
                ...deviceProviders
                    .filter((item) => item.enabled === 1)
                    .map((item) => {
                        return { value: item.value, text: item.key };
                    }),
            ];
        },
        saltoInstallationOptions(){
            return [
                 {
                    value: null,
                    text: this.$t("doors.saltoInstallation_select"),
                    disabled: true,
                },
                 ...this.getSaltoInstallation
                    .filter((item) => (item.CompanyId == this.getActiveCompany.Id) && (item.LocationId == this.locationId) )
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return { value: item.Id, text: item.Name };
                    }),
            ];
        },
        availableDevicesOptions(){
            return [
                {
                    value: null,
                    text: this.$t("doors.availableDevices_select"),
                    disabled: true,
                },
                ...this.getSaltoOnlineDevices
                    .filter((item)=>{
                        let filterOnlineDevice = true;
                        if(this.device.SaltoInstallationId != null){
                           filterOnlineDevice = item.SaltoInstallationId == this.device.SaltoInstallationId
                        }else{
                            return
                        }
                        return filterOnlineDevice;
                    })
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return {
                            value: item.Id,
                            text: item.Name,
                        };
                    }),
            ];
        },
        availableRelaysOptions(){
            return [
                {
                    value: null,
                    text: this.$t("relays.availableRelays_select"),
                    disabled: true,
                },
                ...(this.getSaltoRelaysList?.filter((item) => item.SaltoInstallationId === this.device.SaltoInstallationId)
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        return {
                            value: item.Id,
                            text: item.Name,
                        };
                    }) ?? []),
            ];
        },
        deviceTypesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("doors.type_select"),
                    disabled: true,
                },
                ...doorTypes.map((item) => {
                    return { value: item.value, text: this.$t(item.key) };
                }),
            ];
        },
        doorpadCodeTypesOptions() {
            return [
                {
                    value: null,
                    text: this.$t("doors.code_type_select"),
                    disabled: true,
                },
                ...doorpadCodeTypes.map((item) => {
                    return { value: item.value, text: this.$t(item.key) };
                }),
            ];
        },
        isSaltoInstallationsShown() {
            return this.device.ProviderType === 5 || this.device.ProviderType === 7
        },
        isSaltoRelaysShown() {
            return this.device.ProviderType === 7
        },
        isDoorCodeShown() {
            return this.device.ProviderType === 1 || this.device.ProviderType === 3 || this.device.ProviderType === 5 || this.device.ProviderType === 6 || this.device.ProviderType === 7
        },
    },
    created() {
        this.$store.dispatch(SALTO_INSTALLATION_GET);
        this.$store.dispatch(SALTO_ONLINE_DEVICE_GET);
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.device[name];
            return $dirty && this.submitted ? !$error : null;
        },
        postDevice: function () {
            this.submitted = true;
            this.$v.device.$touch();

            if (this.$v.device.$anyError) {
                return;
            }

            let device = { ...this.device };
            if (device.ProviderType !== 1 ) {
                delete device.DoorCodeType;
            }
            if (device.ProviderType !== 2) {
                delete device.YaleSid;
            }
            if (device.ProviderType !== 3) {
                delete device.HonestBoxLockId;
            }
            if (device.ProviderType === 2) {
                device["YaleZone"] = 0;
                device["YaleArea"] = 1;
                device["YaleType"] = "device_type.door_lock";
            }
            if(device.ProviderType !== 5){
                delete device.SaltoInstallationId;
                delete device.SaltoOnlineDeviceId;
            }
            if (device.ProviderType !== 6) {
                delete device.InletOnlineLockId;
            }
            if(device.ProviderType === 7){
                device.SaltoInstallationId = this.device.SaltoInstallationId
                device.SaltoOnlineRelayId = this.getSaltoRelaysList
                    .find(r =>  device.SaltoOnlineRelayId === r.Id)?.Id
            }
            const doorCodeVal = device.Code;
            this.$store
                .dispatch(DEVICE_CREATE, device)
                .then((response) => {
                    if (this.isDoorCodeShown) {
                        this.$store
                        .dispatch(DEVICE_CODE_PATCH, {
                            deviceId: response.Id,
                            doorcode: doorCodeVal,
                        });
                    }
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        editDevice(response) {
            this.submitted = true;
            this.$v.device.$touch();
            if (this.$v.device.$anyError) {
                return;
            }

            let device = { ...this.getModalData.device };
            if (device.ProviderType === 0 ) {
                delete device.DoorCodeType;
            }
            if (device.ProviderType !== 2) {
                delete device.YaleSid;
            }
            if (device.ProviderType === 3) {
                delete device.HonestBoxLockId;
            }
            if (device.ProviderType === 4) {
                delete device.DoorCodeType;
            }
            if(device.ProviderType !== 5){
                delete device.SaltoInstallationId;
                delete device.SaltoOnlineDeviceId;
            }
            if (device.ProviderType === 6) {
                delete device.InletOnlineLockId;
            }

            this.$store
                .dispatch(DEVICE_PATCH, {...this.device,
                 ...{ ImageId: response.Id },})
                .then(() => {
                    if (this.isDoorCodeShown) {
                        let doorCodeVal = document.getElementById('Code').value;
                        this.$store
                        .dispatch(DEVICE_CODE_PATCH, {
                            deviceId: this.device.Id,
                            doorcode: doorCodeVal,
                        });
                    }
                    this.$store.dispatch(DEVICE_GET, this.device.Id);
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
        },
        setData() {
            if (this.getModalData !== null) {
                this.device = { ...this.getModalData.device };
                if(this.device.ProviderType === 1){
                    let doorCodeType = this.device.DoorCodeType;

                    if(doorCodeType == 'YaleDoorman'){
                        this.device.DoorCodeType = 1;
                    }else if(doorCodeType == 'IDLock'){
                        this.device.DoorCodeType = 2;
                    }else{
                        this.device.DoorCodeType = 3;
                    }
                }
                this.createMode = false;
                let respDoorCode;
                if(this.isDoorCodeShown){
                    this.$store.dispatch(DEVICE_CODE_GET, this.device.Id).then((response) => {
                        respDoorCode = response.doorcode;
                        if(respDoorCode === "undefined" || respDoorCode === null){
                            respDoorCode = "";
                        }
                        respDoorCode = '' + respDoorCode
                        if (respDoorCode.length < 4)
                            respDoorCode = ('0000' + respDoorCode).slice(-4);
                        document.getElementById('Code').value = respDoorCode;
                        this.device["Code"] = respDoorCode;
                    })
                    .catch((error) => {});
                }
                if(this.device.ProviderType === 5){
                    this.getSaltoInstallation
                    .filter((item) => item.Id == this.device.SaltoInstallationId)
                    .sort((i1, i2) => {
                        return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                    })
                    .map((item) => {
                        this.device.SaltoInstallationId =  item.Id;
                    })
                }

            } else {
                this.device = { ...this.deviceTemplate };
                this.createMode = true;
            }
            if (this.getActiveCompany) {
                this.$store
                    .dispatch(CHECK_DANALOCK, this.getActiveCompany.OwnerId)
                    .then((response) => {
                        this.ownerDanalockConnected = response;
                    });
            }

            this.$v.device.$reset();
            if (this.device.SaltoInstallationId) {
                this.setRelaysList(this.device.SaltoInstallationId);
            }
        },
        onClose() {
            this.submitted = false;
            this.$v.device.$reset();
            this.createMode = true;
            this.device = { ...this.deviceTemplate };
            this.$store.commit(CLEAR_MODAL_DATA);
        },
        setRelaysList(value) {
            const installationShortName = this.getSaltoInstallation.find(i => value === i.Id)?.ShortName
            this.$store.dispatch(SALTO_RELAYS_GET, installationShortName)
        },
    },
};
</script>
<style scoped>
.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}
input:disabled {
    color: #95aac9;
    background-color: #edf2f9;
}
</style>
