<template>
    <div class="card-body" v-if="activityLog.length">       
        <!-- List group -->
        <div class="list-group list-group-flush list-group-activity my-n3">            
            <template v-for="item in activityLog">            
                <zs-activity-device-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-updated
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-created
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_CREATED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-locked
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_LOCKED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />                
                <zs-activity-device-unlocked
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked-dialpad-fail
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked-dialpad-success
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-lock-fail
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_LOCK_FAIL"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlock-fail
                    v-if="item.Type == ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-online-device-deleted
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-online-device-updated
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-salto-online-device-created
                    v-if="(item.Type == ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-devices-connected
                    v-if="(item.Type == ACTIVITY_TYPE_CONNECT_DEVICES)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-devices-disconnected
                    v-if="(item.Type == ACTIVITY_TYPE_DISCONNECT_DEVICES)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <!-- <zs-activity-device-locked-user
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_LOCKED_USER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />
                <zs-activity-device-unlocked-user
                    v-if="(item.Type == ACTIVITY_TYPE_DEVICE_UNLOCKED_USER)"
                    v-bind:key="item.Id"
                    :activityItem="item"
                />  -->        
            </template>
        </div>
    </div>
    <div class="card-body" v-else>
        <empty-list :title="$t('activity.no_activity')" />
    </div>
</template>

<script>
import ActivityDeviceDeleted from "@/components/activity/ActivityDeviceDeleted";
import ActivityDeviceUpdated from "@/components/activity/ActivityDeviceUpdated";
import ActivityDeviceCreated from "@/components/activity/ActivityDeviceCreated";
import ActivityDeviceLocked from "@/components/activity/ActivityDeviceLocked";
import ActivityDeviceUnlocked from "@/components/activity/ActivityDeviceUnlocked";
import ActivityDeviceLockedFail from "@/components/activity/ActivityDeviceLockedFail";
import ActivityDeviceUnlockedFailed from "@/components/activity/ActivityDeviceUnlockedFailed";
import ActivityDeviceUnlockedDialpadFail from "@/components/activity/ActivityDeviceUnlockedDialpadFail";
import ActivityDeviceUnlockedDialpadSuccess from "@/components/activity/ActivityDeviceUnlockedDialpadSuccess";
import ActivitySaltoOnlineDeviceDeleted from "@/components/activity/ActivitySaltoOnlineDeviceDeleted";
import ActivitySaltoOnlineDeviceUpdated from "@/components/activity/ActivitySaltoOnlineDeviceUpdated";
import ActivitySaltoOnlineDeviceCreated from "@/components/activity/ActivitySaltoOnlineDeviceCreated";
import ActivityDevicesConnected from "@/components/activity/ActivityDevicesConnected";
import ActivityDevicesDisconnected from "@/components/activity/ActivityDevicesDisconnected";
import EmptyList from "@/components/common/EmptyList";

import {
    ACTIVITY_TYPE_ROLE_SET,
    ACTIVITY_TYPE_KEY_CREATED,
    ACTIVITY_TYPE_KEY_DELETED,
    ACTIVITY_TYPE_KEY_UPDATED,
    ACTIVITY_TYPE_DEVICE_UPDATED,
    ACTIVITY_TYPE_DEVICE_DELETED,
    ACTIVITY_TYPE_DEVICE_CREATED,
    ACTIVITY_TYPE_LOCATION_CREATED,
    ACTIVITY_TYPE_LOCATION_UPDATED,
    ACTIVITY_TYPE_LOCATION_DELETED,
    ACTIVITY_TYPE_INVITE_CREATED,
    ACTIVITY_TYPE_INVITE_ACCEPTED,
    ACTIVITY_TYPE_DEVICE_LOCKED,
    ACTIVITY_TYPE_DEVICE_UNLOCKED,
    // ACTIVITY_TYPE_DEVICE_LOCKED_USER,
    // ACTIVITY_TYPE_DEVICE_UNLOCKED_USER,
    ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
    ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,
    ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
    ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
    ACTIVITY_TYPE_COMPANY_CREATED,
    ACTIVITY_TYPE_COMPANY_UPDATED,
    ACTIVITY_TYPE_COMPANY_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
    ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
    ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
    ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,   
    ACTIVITY_TYPE_REMOVED_STAFF,
    ACTIVITY_TYPE_CONNECT_DEVICES,
    ACTIVITY_TYPE_DISCONNECT_DEVICES
    
} from "@/handlers/const";

import ac from "@/api/activity";
export default {
    data() {
        return {
            ACTIVITY_TYPE_ROLE_SET: ACTIVITY_TYPE_ROLE_SET,
            ACTIVITY_TYPE_KEY_CREATED: ACTIVITY_TYPE_KEY_CREATED,
            ACTIVITY_TYPE_KEY_DELETED: ACTIVITY_TYPE_KEY_DELETED,
            ACTIVITY_TYPE_KEY_UPDATED: ACTIVITY_TYPE_KEY_UPDATED,
            ACTIVITY_TYPE_DEVICE_UPDATED: ACTIVITY_TYPE_DEVICE_UPDATED,
            ACTIVITY_TYPE_INVITE_CREATED: ACTIVITY_TYPE_INVITE_CREATED,
            ACTIVITY_TYPE_INVITE_ACCEPTED: ACTIVITY_TYPE_INVITE_ACCEPTED,
            ACTIVITY_TYPE_LOCATION_CREATED: ACTIVITY_TYPE_LOCATION_CREATED,
            ACTIVITY_TYPE_LOCATION_UPDATED: ACTIVITY_TYPE_LOCATION_UPDATED,
            ACTIVITY_TYPE_LOCATION_DELETED: ACTIVITY_TYPE_LOCATION_DELETED,
            ACTIVITY_TYPE_DEVICE_DELETED: ACTIVITY_TYPE_DEVICE_DELETED,
            ACTIVITY_TYPE_DEVICE_CREATED: ACTIVITY_TYPE_DEVICE_CREATED,
            ACTIVITY_TYPE_DEVICE_LOCKED: ACTIVITY_TYPE_DEVICE_LOCKED,
            ACTIVITY_TYPE_DEVICE_UNLOCKED: ACTIVITY_TYPE_DEVICE_UNLOCKED,
            // ACTIVITY_TYPE_DEVICE_LOCKED_USER: ACTIVITY_TYPE_DEVICE_LOCKED_USER,
            // ACTIVITY_TYPE_DEVICE_UNLOCKED_USER: ACTIVITY_TYPE_DEVICE_UNLOCKED_USER,
            ACTIVITY_TYPE_DEVICE_LOCK_FAIL: ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
            ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL: ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,     
            ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL: ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
            ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS: ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,      
            ACTIVITY_TYPE_USER_REMOVED_FROM_KEY: ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
            ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION: ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
            ACTIVITY_TYPE_COMPANY_CREATED: ACTIVITY_TYPE_COMPANY_CREATED,
            ACTIVITY_TYPE_COMPANY_UPDATED: ACTIVITY_TYPE_COMPANY_UPDATED,
            ACTIVITY_TYPE_COMPANY_DELETED: ACTIVITY_TYPE_COMPANY_DELETED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
            ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED: ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
            ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER: ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
            ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER: ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,      
            ACTIVITY_TYPE_REMOVED_STAFF:  ACTIVITY_TYPE_REMOVED_STAFF,
            ACTIVITY_TYPE_CONNECT_DEVICES: ACTIVITY_TYPE_CONNECT_DEVICES,
            ACTIVITY_TYPE_DISCONNECT_DEVICES: ACTIVITY_TYPE_DISCONNECT_DEVICES,    
            adminUser: false,
        };
    },
    props: {
        activityLog: Array,
    },
    created(){
        this.adminUser = this.$session.get("adminUser");
    },
    methods: {},
    components: {
        "zs-activity-device-deleted": ActivityDeviceDeleted,
        "zs-activity-device-updated": ActivityDeviceUpdated,
        "zs-activity-device-created": ActivityDeviceCreated,
        "zs-activity-device-locked": ActivityDeviceLocked,
        "zs-activity-device-unlocked": ActivityDeviceUnlocked,
        "zs-activity-device-unlocked-dialpad-fail": ActivityDeviceUnlockedDialpadFail,
        "zs-activity-device-unlocked-dialpad-success": ActivityDeviceUnlockedDialpadSuccess,        
        "zs-activity-device-lock-fail": ActivityDeviceLockedFail,
        "zs-activity-device-unlock-fail": ActivityDeviceUnlockedFailed,
        "zs-activity-salto-online-device-deleted": ActivitySaltoOnlineDeviceDeleted,
        "zs-activity-salto-online-device-updated": ActivitySaltoOnlineDeviceUpdated,
        "zs-activity-salto-online-device-created": ActivitySaltoOnlineDeviceCreated,
        "zs-activity-devices-connected": ActivityDevicesConnected,
        "zs-activity-devices-disconnected":  ActivityDevicesDisconnected,
        // "zs-activity-device-locked-user": ActivityDeviceLockedUser,
        // "zs-activity-device-unlocked-user": ActivityDeviceUnlockedUser,        
        "empty-list": EmptyList,
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: hidden !important;
}
</style>
