<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <!-- <b-card transition="fade">
                    <template #header v-if="isMobile || (this.width<700)"> -->
                <div class="card">
                    <div class="card-header" v-if="isMobile || (this.width<700)">
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3" v-show="tabIndex == 1">
                            <Select2
                                v-model="perUserPage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedDeviceUserList"
                            />
                        </form>
                        <form class="mr-3" v-show="tabIndex == 2">
                            <Select2
                                v-model="perKeyPage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedDeviceKeyList"
                            />
                        </form>
                        <form class="mr-3" v-show="tabIndex == 2">
                            <Select2
                                :options="getUnassignedKeysList"
                                :disabled="getUnassignedKeysList.length == 0"
                                class= "select-dropdown"
                                @select="addKey"
                                :settings="{
                                    placeholder: $t('doors.add_key'),
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                                }"
                            />
                        </form>
                        <form class="col-auto mr-n3" v-show="tabIndex == 3">
                            <Select2
                            v-model="perPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass: 'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="onPerPageChanged"
                            />
                        </form>
                    </div>
                    <!-- </template>
                    <template #header v-else> -->
                    <div class="card-header" v-else>
                        <h4 class="card-header-title"></h4>
                        <form class="mr-3" v-show="tabIndex == 1">
                            <Select2
                                v-model="perUserPage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedDeviceUserList"
                            />
                        </form>
                        <form class="mr-3" v-show="tabIndex == 2">
                            <Select2
                                v-model="perKeyPage"
                                :options="perPageOptions"
                                :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                    'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                }"
                                @change="paginatedDeviceKeyList"
                            />
                        </form>
                        <form class="mr-3" v-show="tabIndex == 2" >
                            <Select2
                                :options="getUnassignedKeysList"
                                :disabled="getUnassignedKeysList.length == 0"
                                class= "select-dropdown"
                                @select="addKey"
                                :settings="{
                                    placeholder: $t('doors.add_key'),
                                    allowClear: true,
                                    minimumResultsForSearch: 1,
                                    containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                    dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                                }"
                            />
                        </form>
                        <form class="col-auto mr-n3" v-show="tabIndex == 3">
                            <Select2
                            v-model="perPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                        'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass:
                                        'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="onPerPageChanged"
                            />
                        </form>
                    </div>
                    <!-- </template> -->
                    <div class="card-header" v-if="tabIndex == 1">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">

                                <b-form-input
                                    :placeholder="$t('common.search')"
                                    v-model="filterUser"
                                    class="form-control form-control-prepended search"
                                    type="search"
                                ></b-form-input>

                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                    </form>
                    </div>
                    <div class="card-header" v-if="tabIndex == 2">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">

                                <b-form-input
                                    :placeholder="$t('common.search')"
                                    v-model="filterKey"
                                    class="form-control form-control-prepended search"
                                    type="search"
                                ></b-form-input>

                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                    </form>
                    </div>
                    <div class="card-body">
                    <div v-show="tabIndex == 0" transition="fade">
                        <table style="text-align: left !important; border-collapse: separate !important; border-spacing: 28px 0px !important;">
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.provider") }}:</td>
                                <td>{{ getProvider() }}</td>
                            </tr>
                        <tr v-if="device.Device.ProviderType == 0">
                            <td class="font-weight-bold">{{ $t("doors.bleid") }}:</td>
                            <td>{{ device.Device.SerialNumber }}</td>
                        </tr>
                        <tr v-if="device.Device.ProviderType == 2">
                            <td> class="font-weight-bold">{{ $t("doors.yale_sid") }}:</td>
                            <td>{{ device.Device.YaleSid }}</td>
                        </tr>
                        <tr v-if="device.Device.ProviderType == 3">
                            <td class="font-weight-bold">{{ $t("doors.honest_box_lock_id") }}:</td>
                            <td>{{ device.Device.HonestBoxLockId }}</td>
                        </tr>
                        <tr v-if="device.Device.ProviderType == 6">
                            <td class="font-weight-bold">{{ $t("doors.inlet_online_lock_id") }}:</td>
                            <td>{{ device.Device.InletOnlineLockId }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">{{ $t("doors.type") }}:</td>
                            <td>{{ getDeviceType() }}</td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">{{ $t("doors.deviceid") }}:</td>
                          <td>{{ device.Device.Id }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">{{ $t("keys.keys") }}:</td>
                            <td>{{ device.KeysCount || 0 }}</td>
                        </tr>
                        <tr>
                            <td class="font-weight-bold">{{ $t("users.users") }}:</td>
                            <td>{{ device.UsersCount || 0 }}</td>
                        </tr>
                        </table>

                        <br/>
                        <table style="text-align: left !important; border-collapse: separate !important; border-spacing: 28px 0px !important;"
                               v-if="(device.Device.ProviderType == 3) && (getMxDeviceInfo)"
                        >
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.mx_updated") }}:</td>
                                <td><b-badge :variant="getTimeBadge(getMxDeviceInfo.UpdatedAt)">{{ getTimeStr(getMxDeviceInfo.UpdatedAt) }}</b-badge></td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.mx_version") }}:</td>
                                <td>{{ getMxDeviceInfo.DeviceVersion }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.mx_uptime") }}:</td>
                                <td>{{ getMxDeviceInfo.DeviceUptimeStr }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.mx_temperature") }}:</td>
                                <td>{{ getMxDeviceInfo.LTETemperature }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.mx_lte_signal") }}:</td>
                                <td>{{ getMxDeviceInfo.LTESignalStrength }}</td>
                            </tr>
                            <tr v-if="getMxDeviceInfo.WIFISignalStrength">
                                <td class="font-weight-bold">{{ $t("doors.mx_wifi_signal") }}:</td>
                                <td>{{ getMxDeviceInfo.WIFISignalStrength }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.mx_lte_connect") }}:</td>
                                <td>{{ getMxDeviceInfo.LTELastConnectedTime }}</td>
                            </tr>
                            <tr v-if="getMxDeviceInfo.WIFILastConnectedTime">
                                <td class="font-weight-bold">{{ $t("doors.mx_wifi_connect") }}:</td>
                                <td>{{ getMxDeviceInfo.WIFILastConnectedTime }}</td>
                            </tr>
                        </table>
                        <table style="text-align: left !important; border-collapse: separate !important; border-spacing: 28px 0px !important;"
                               v-if="(device.Device.ProviderType === 4) && (getSaltoDeviceInfo) && (getSaltoDeviceInfo.offline)"
                        >
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_short_name") }}:</td>
                                <td>{{ getSaltoDeviceInfo.saltoInstallationShortName }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_key_valid") }}:</td>
                                <td><b-badge :variant="getBoolBadge(getSaltoDeviceInfo.offline.keyValid)">{{ getBoolYNStr(getSaltoDeviceInfo.offline.keyValid) }}</b-badge></td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_key_valid_to") }}:</td>
                                <td><b-badge :variant="getTimeValidBadge(getSaltoDeviceInfo.offline.keyValidUntil)">{{ getTimeStr(getSaltoDeviceInfo.offline.keyValidUntil) }}</b-badge></td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_user_id") }}:</td>
                                <td>{{ getSaltoDeviceInfo.offline.saltoUserId }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_user_updated_at") }}:</td>
                                <td><b-badge variant="info">{{ getTimeStr(getSaltoDeviceInfo.offline.userUpdatedAt) }}</b-badge></td>
                            </tr>
                        </table>
                        <table style="text-align: left !important; border-collapse: separate !important; border-spacing: 28px 0px !important;"
                               v-if="(device.Device.ProviderType === 5) && (getSaltoDeviceInfo) && (getSaltoDeviceInfo.online)"
                        >
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_short_name") }}:</td>
                                <td>{{ getSaltoDeviceInfo.saltoInstallationShortName }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_door_id") }}:</td>
                                <td>{{ getSaltoDeviceInfo.online.saltoDoorId }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_device_updated_at") }}:</td>
                                <td><b-badge variant="info">{{ getTimeStr(getSaltoDeviceInfo.online.saltoDeviceUpdatedAt) }}</b-badge></td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_battery_level") }}:</td>
                                <td><b-badge :variant="getBatteryLevelBadge(getSaltoDeviceInfo.online.batteryLevel)">{{ getSaltoDeviceInfo.online.batteryLevel }}%</b-badge></td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_battery_status") }}:</td>
                                <td>{{ getBatteryStatusStr(getSaltoDeviceInfo.online.batteryStatus) }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_door_comm_status") }}:</td>
                                <td>{{ getCommStatusStr(getSaltoDeviceInfo.online.doorCommStatus) }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_door_status") }}:</td>
                                <td>{{ getDoorStatusStr(getSaltoDeviceInfo.online.doorStatus) }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ $t("doors.salto_door_update_req") }}:</td>
                                <td>{{ getBoolYNStr(getSaltoDeviceInfo.online.doorUpdateRequired) }}</td>
                            </tr>
                        </table>
                    </div>
                    <div v-show="tabIndex == 1" transition="fade">
                        <b-pagination
                            v-model="currentUserPage"
                            :total-rows="getDeviceUsers.length"
                            :per-page="perUserPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showUsersLoader">
                            <template v-slot:content>
                               <!--  v-for="user in getDeviceUsers"   -->
                                <door-user-item
                                    v-for="user in paginatedDeviceUserList"
                                    v-bind:key="user.User.Id"
                                    :user="user"
                                    :showDeleting="
                                        $can('shareKey', getActiveLocation)
                                    "
                                />
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('doors.no_users')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentUserPage"
                            :total-rows="getDeviceUsers.length"
                            :per-page="perUserPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>

                    <div v-show="tabIndex == 2" transition="fade">
                        <b-pagination
                            v-model="currentKeyPage"
                            :total-rows="getDeviceKeys.length"
                            :per-page="perKeyPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                        <zs-list-transition :showLoader="showKeysLoader">
                            <template v-slot:content>
                               <!--  v-for="doorKey in getDeviceKeys" -->
                                <b-list-group-item
                                    v-for="doorKey in paginatedDeviceKeyList"
                                    v-bind:key="doorKey.Id"
                                    class="flex-column align-items-start"
                                >
                                <!-- style="display: -webkit-box !important;" -->
                                    <b-row align-v="center">
                                        <div class="col-auto">
                                            <!-- Avatar -->
                                            <div class="avatar avatar-sm">
                                                <div
                                                    class="avatar-title font-size-lg bg-primary-soft rounded-circle text-primary"
                                                >
                                                <router-link
                                                    :to="{
                                                        name: 'key',
                                                        params: {
                                                            locationId:
                                                                $route.params
                                                                    .locationId,
                                                            companyId:
                                                                $route.params
                                                                    .companyId,
                                                            keyId: doorKey.Id,
                                                        },
                                                    }"
                                                    >
                                                    <!-- <i class="fi flaticon-key"></i> -->
                                                <img :src="'/images/Key_MyZesec_64dp.png'" style="width: 32px; height: 32px;"/>
                                                </router-link>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- col-auto -->
                                        <b-col class="col-8">
                                            <h4 class="mb-1 name">
                                                <!--  class="ml-2" -->
                                                <router-link
                                                    :to="{
                                                        name: 'key',
                                                        params: {
                                                            locationId:
                                                                $route.params
                                                                    .locationId,
                                                            companyId:
                                                                $route.params
                                                                    .companyId,
                                                            keyId: doorKey.Id,
                                                        },
                                                    }"
                                                    >{{
                                                        keyName(doorKey.Name)
                                                    }}</router-link
                                                >
                                            </h4>
                                        </b-col>
                                        <b-col class="col ml-n2">
                                            <b-dropdown
                                                variant="outline"
                                                toggle-class="dropdown-ellipses dropdown-toggle"
                                                class="mx-1 btn-sm float-right"
                                                no-caret
                                                right
                                                toggle-tag="a"
                                            >
                                                <template v-slot:button-content>
                                                    <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                                </template>
                                                <b-dropdown-item
                                                    class="text-left text-truncate"
                                                    @click="deleteDoor(doorKey.Id)"
                                                    >{{ $t("buttons.disconnect_door") }}</b-dropdown-item
                                                >
                                            </b-dropdown>
                                        </b-col>
                                    </b-row>
                                </b-list-group-item>
                            </template>
                            <template v-slot:empty>
                                <empty-list :title="$t('doors.no_keys')" />
                            </template>
                        </zs-list-transition>
                        <b-pagination
                            v-model="currentKeyPage"
                            :total-rows="getDeviceKeys.length"
                            :per-page="perKeyPage"
                            aria-controls="my-table"
                            class="pb-2"
                        ></b-pagination>
                    </div>
                    <div v-show="tabIndex == 3" transition="fade">
                        <!-- <zs-activity-timeline
                            v-show="tabIndex == 3"
                            transition="fade"
                            v-if="activity.length && !loading"
                            :activityLog="activity"
                        /> -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        class="pb-2"
                      ></b-pagination>
                      <div v-if="loading" class="d-flex justify-content-center text-center my-3">
                        <b-spinner :label="$t('common.loading')"></b-spinner>
                      </div>
                      <div v-else-if="activity.length">
                        <zs-activity-device-timeline
                            v-show="tabIndex == 3"
                            transition="fade"
                            v-if="activity.length && !loading"
                            :activityLog="paginatedDeviceActivityList"
                        />
                      </div>
                      <div v-else>
                        <empty-list :title="$t('doors.no_activity')" />
                      </div>
                        <b-pagination
                                v-if="!loading"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                aria-controls="my-table"
                                class="pb-2"
                        ></b-pagination>
                    </div>
                </div>
                </div>
                <!-- </b-card> -->
            </b-col>
        </b-row>
        <zs-device-modal :locationId="device.Device.LocationId" />
        <zs-delete-door-user-modal />
    </b-container>
</template>

<script>
import CreateDeviceModal from "@/components/modals/CreateDevice";
import DeleteDeviceUser from "@/components/modals/DeleteDeviceUser";
import Select2 from "@/components/common/Select2";
import ListTransition from "@/components/common/ListTransition";
import DeviceTimeline from "@/components/activity/DeviceTimeline";
import DoorUserItem from "@/components/device/DoorUserItem";
import EmptyList from "@/components/common/EmptyList";

import { mapGetters } from "vuex";

import {
    KEYS_GET,
    KEY_DEVICE_REMOVE,
    KEY_DEVICE_ADD,
} from "@/store/types/keys";

import {
    DEVICE_KEYS_GET,
    DEVICE_USERS_GET,
    DEVICE_PERMANENT_USERS_GET,
    DEVICE_GET,
    DEVICE_CODE_GET,
} from "@/store/types/devices";

import activityMixin from "@/mixins/activityMixin";
import modalMixin from "@/mixins/modalMixin";
import { deviceProviders, doorTypes } from "@/handlers/enums";
import { ACTIVITY_LOG_DEVICE, ACTIVITY_LOG_DEVICE_PG } from "@/handlers/const";
import { inviteStatuses } from "@/handlers/enums";
import { sleep } from "@/store/modules/global";

export default {
    mixins: [activityMixin, modalMixin],
    data() {
        return {
            isManagerRole: false,
            activity: [],
            newList: [],
            user: {},
            userId: null,
            key: null,
            showUsersLoader: true,
            showKeysLoader: true,
            isMobile: false,
            width: window.innerWidth,
            height: window.innerHeight,
            perUserPage: 20,
            currentUserPage: 1,
            perKeyPage: 20,
            currentKeyPage: 1,
            perPage: 20,
            currentPage: 1,
            perPageOptions: [
                { id: 10, text: "10 " + this.$t("menu.per_page") },
                { id: 20, text: "20 " + this.$t("menu.per_page") },
                { id: 50, text: "50 " + this.$t("menu.per_page") },
            ],
            filterUser: "",
            filterKey: "",
        };
    },
    props: ["device", "tabIndex"],
    computed: {
        ...mapGetters([
            "getUser",
            "getLocationKeys",
            "getInitProcessing",
            "getDeviceKeys",
            "getDeviceUsers",
            "getDevicePermanentUsers",
            "getActiveLocation",
            "getMxDeviceInfo",
            "getSaltoDeviceInfo",
            "getActiveUserRole",
        ]),
        getUnassignedKeysList() {
            return this.getLocationKeys
                .filter((item) => {
                    if (!this.getDeviceKeys.length) {
                        return true;
                    }
                    return !this.getDeviceKeys.find((key) => key.Id == item.Id);
                })
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((key) => {
                    if(key.Name.includes("Key of: ")){
                        key.Name = key.Name.replace("Key of: ","");
                    }
                    return { id: key.Id, text: key.Name };
                });
        },
        deviceUsersList(){
            let devusers = this.getDeviceUsers
            return devusers.filter(item => item.RoleId !== 7 || devusers.findIndex(o => o.UserId === item.UserId && o.RoleId === 6) < 0)
                .filter((item)=>item.User.Name.toLowerCase().includes(this.filterUser.toLowerCase()))
        },
        paginatedDeviceUserList() {
            return this.deviceUsersList.slice(
                (this.currentUserPage - 1) * this.perUserPage,
                this.currentUserPage * this.perUserPage
            );
        },
        deviceKeysList(){
            return this.getDeviceKeys
                .filter((item)=>item.Name.toLowerCase().includes(this.filterKey.toLowerCase()))
        },
        paginatedDeviceKeyList() {
            return this.deviceKeysList.slice(
                (this.currentKeyPage - 1) * this.perKeyPage,
                this.currentKeyPage * this.perKeyPage
            );
        },
        paginatedDeviceActivityList() {
          if (this.currentPage === this.currPage + 1) {
            return this.activity
          }
          else if (this.currentPage === this.nextPage + 1) {
            return this.activityNext
          }
          else {
            if (this.currentPage === 1)
              this.initActivity()
            else
              this.loadActivityPage(this.currentPage - 1)

            return this.activity
          }
        },
    },
    async created() {
        await Promise.all([
            this.$store.dispatch(KEYS_GET, this.$route.params.locationId),
            this.$store
                    .dispatch(DEVICE_KEYS_GET, this.$route.params.doorId),
            this.$store
                    .dispatch(DEVICE_USERS_GET, this.$route.params.doorId),
        ]);
        this.showUsersLoader = false
        this.showKeysLoader = false
        window.addEventListener("resize", this.onResize);
        window.addEventListener("onload", this.onLoad);

        // wait for init completed
        for (let i = 0; i < 30; i++) {
            await sleep(100)
            if (this.getInitProcessing === false) {
                break
            }
        }

        this.userId = this.getUser?.Id

        if( this.getActiveUserRole == "CompanyOwner" || this.getActiveUserRole == "CompanyManager" || this.getActiveUserRole == "LocationManager" || this.getActiveUserRole == "KeyManager"){
          this.isManagerRole = true
        }

        this.initActivity();
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        this.isMobile = false;
    },
    methods: {
        initActivity() {
            this.loadActivityPage(0)
        },
        loadActivityPage(pageNum) {
            this.activity = [];
            const params = {
                deviceId: this.$route.params.doorId,
                UserId: (!this.isManagerRole && this.userId)? this.userId : null,
                IgnorePagination: false,
                limit: this.perPage,
                page: (pageNum) ? pageNum : 0,
            }

            if (params.page === 0 || !this.totalRows)
              this.loadActivityPg(params, ACTIVITY_LOG_DEVICE_PG);

            this.loadActivity(params, ACTIVITY_LOG_DEVICE);
        },
        onPerPageChanged() {
          this.initActivity();
        },
        convertTime(timeStr) {
            let time = this.$moment(timeStr)
            if (!timeStr.includes("+") && (timeStr.split("-").length < 4) && !timeStr.includes("Z") && !timeStr.includes("z")) {
                time = this.$moment(timeStr + 'Z')
            }
            return time;
        },
        getTimeStr(time) {
            let date = this.convertTime(time)
            return date.local().format("YYYY/MM/DD HH:mm:ss")
        },
        getBoolBadge(value) {
            if (value)
                return "success"
            else
                return "danger"
        },
        getTimeBadge(time) {
            let d1 = this.convertTime(time)
            let diff = ((this.$moment()).valueOf() - d1.valueOf()) / 1000 / 60;
            if (diff < -1)
                return "info"
            if (diff < 10)
                return "success"
            if (diff < 60)
                return "warning"
            return "danger"
        },
        getTimeValidBadge(time) {
            let d1 = this.convertTime(time)
            let diff = (d1.valueOf() - (this.$moment()).valueOf()) / 1000 / 60 / 60;
            if (diff > 36)
                return "success"
            if (diff > 0)
                return "warning"
            return "danger"
        },
        getBatteryLevelBadge(level) {
            if (level > 70)
                return "success"
            if (level > 1)
                return "warning"
            return "danger"
        },
        getBatteryStatusStr(status) {
            if (status === 0)
                return this.$t("salto.battery_status_normal")
            if (status === 1)
                return this.$t("salto.battery_status_low")
            if (status === 2)
                return this.$t("salto.battery_status_very_low")
            if (status === -1)
                return this.$t("salto.status_unknown")

            return this.$t("salto.status_unknown_param", {status: status})
        },
        getCommStatusStr(status) {
            if (status === 0)
                return this.$t("salto.comm_no_communication")
            if (status === 1)
                return this.$t("salto.comm_ok")
            if (status === -1)
                return this.$t("salto.status_unknown")

            return this.$t("salto.status_unknown_param", {status: status})
        },
        getDoorStatusStr(status) {
            if (status === 0)
                return this.$t("salto.door_opened")
            if (status === 1)
                return this.$t("salto.door_closed")
            if (status === 2)
                return this.$t("salto.door_left_opened")
            if (status === 3)
                return this.$t("salto.door_intrusion")
            if (status === 4)
                return this.$t("salto.door_emergency_opened")
            if (status === 5)
                return this.$t("salto.door_emergency_closed")
            if (status === 6)
                return this.$t("salto.door_initializing")
            if (status === -1)
                return this.$t("salto.status_unknown")

            return this.$t("salto.status_unknown_param", {status: status})
        },
        getBoolYNStr(status) {
            if (status === 0 || status === false)
                return this.$t("salto.bool_no")
            if (status === 1 || status === true)
                return this.$t("salto.bool_yes")

            return this.$t("salto.status_unknown_param", {status: status})
        },
        addKey(key) {
            this.$store.dispatch(KEY_DEVICE_ADD, {
                keyId: key.id,
                deviceId: this.device.Device.Id,
                locationId: this.device.Device.LocationId,
            });
        },
        deleteDoor(keyId) {
            this.$store.dispatch(KEY_DEVICE_REMOVE, {
                keyId: keyId,
                deviceId: this.device.Device.Id,
                locationId: this.device.Device.LocationId,
            });
        },
        getStatusColor(statusId) {
            return inviteStatuses.find((item) => item.value == statusId)
                .variant;
        },
        getProvider() {
            return deviceProviders.find(
                (item) => item.value == this.device.Device.ProviderType
            )["key"];
        },
        getDeviceType() {
            return this.$t(
                doorTypes.find(
                    (item) => item.value == this.device.Device.DoorType
                )["key"]
            );
        },
        keyName(name){
            if(name.includes("Key of: ")){
                name = name.replace("Key of: ","");
            }
            return name;
        },
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
    },
    components: {
        "door-user-item": DoorUserItem,
        Select2,
        EmptyList,
        "zs-list-transition": ListTransition,
        "zs-activity-device-timeline": DeviceTimeline,
        "zs-device-modal": CreateDeviceModal,
        "zs-delete-door-user-modal": DeleteDeviceUser,
    },
};
</script>
<style  scoped>
.select2-container{
    left: 307.25px !important;
}
.select2-dropdown .dropdown-menu .select-dropdown{
  left: -32px !important;
}
.select2-search__field {
    width: 100% !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: 100% !important;
}
.list-group-item:last-child{
  overflow-y: hidden !important;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
